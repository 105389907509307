<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config.json";
import {mapState} from "vuex";

import Multiselect from "vue-multiselect";
import { VclCode } from 'vue-content-loading';
import {
  required,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import {with_acrf_token} from "@/methods";


export default {
  page: {
    title: "Server setup",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    VclCode,
    Multiselect
  },
  validations: {
    quick_connect: {
      rcon_port: {minValue: minValue(1), maxValue: maxValue(65535)},
      rcon_password: {required}
    },
    direct_connect: {
      host: {required},
      game_port: {required, minValue: minValue(1), maxValue: maxValue(65535)},
      rcon_port: {required, minValue: minValue(1), maxValue: maxValue(65535)},
      rcon_password: {required}
    }
  },
  methods: {
    formSubmit(e) {
      if(this.directConnectForm) {
        this.$v.direct_connect.$touch();
        if (this.$v.direct_connect.$invalid) return;

        this.inProgress = true;
        this.handleDirectConnect();
      } else {
        this.$v.quick_connect.$touch();
        if (this.$v.quick_connect.$invalid) return;

        this.inProgress = true;
        this.handleQuickConnect();
      }
    },
    handleDirectConnect: function() {
      let payload = {
        game: this.game.id,
        host: this.direct_connect.host,
        game_port: this.direct_connect.game_port,
        rcon_password: this.direct_connect.rcon_password,
        rcon_port: this.direct_connect.rcon_port
      }
      this.completeSetup(payload);
    },
    completeSetup: function(payload) {
      with_acrf_token().then((r) => {
        payload.acsrf_token = r.acsrf_token;
        fetch(process.env.VUE_APP_ROOT_API + 'v1/servers/complete-setup', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
          .then(response => {
            if(response.ok || response.status === 400 || response.status === 409){
              return response.json();
            } else {
              if(response.status === 429) {
                this.$swal({
                  icon: 'warning',
                  text: this.$t('error.server.ratelimit.message'),
                  title: this.$t('error.server.ratelimit.title')
                });
              } else
                throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            if(!data.status) {
                let error = data.error;
                if(error === 'not-eligable') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.not_eligable.no_subscription.title')
                  });
                } else if(error === 'restricted') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.not_eligable.restricted.title')
                  });
                } else if(error === 'bad-hostname') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.server.error.hostname')
                  });
                } else if(error === 'rcon-socket-error') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.server.error.rcon_socket')
                  });
                } else if(error === 'rcon-auth-error') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.server.error.rcon_auth')
                  });
                } else if(error === 'already-claimed') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.server.error.already_claimed')
                  });
                } else if(error === 'forbidden-host') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.server.error.forbidden')
                  });
                } else if(error === 'unknown-server') {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.server.error.unknown_server')
                  });
                } else {
                  this.$swal({
                    icon: 'error',
                    text: this.$t('server_add.server.error.generic')
                  });
                }
            } else {
              this.pending_server_id = data.server.id;
              let server_id = this.pending_server_id;
              this.$socket.$subscribe('server:state', event => {
                if(event.server.id === server_id) {
                  this.deployConfirmed = true;
                }
              });
              this.toggleOverlay();
              this.scheduleDeployScreenUpdate();
            }
            this.inProgress = false;
          })
          .catch(error => {
            console.log(`[ERROR] ${error}`);
            this.$swal({
              icon: 'error',
              text: this.$t('server_add.server.error.generic')
            });
            this.inProgress = false;
          });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
        this.inProgress = false;
      });
    },
    handleQuickConnect: function() {
      let payload = {
        game: this.game.id,
        host: this.server.host.address,
        game_port: this.server.host.game_port,
        rcon_password: this.quick_connect.rcon_password,
        rcon_port: this.quick_connect.rcon_port
      }
      this.completeSetup(payload);
    },
    resetForms: function() {
      this.inProgress = false;
      this.quick_connect.rcon_password = null;
      this.quick_connect.rcon_port = null;
      this.direct_connect.rcon_port = null;
      this.direct_connect.rcon_password = null;
      this.direct_connect.game_port = null;
      this.direct_connect.host = null;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/servers/eligible', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          console.log(data);
          this.limits = data.limits;
          this.limits_reached = data.limits_reached
          this.eligible = data.eligible;
          this.ready = true;
        })
    },
    scheduleDeployScreenUpdate() {
      let timeout;
      let that = this;
      let min = 2000;
      let max = 8000;

      if(this.deployStep === 6) {
        if(this.deployConfirmed) {
          /* Break out if deploy has been confirmed */
          this.toggleOverlay();
          this.$router.push(`/server/${this.deployedServerId}`);
          return;
        } else timeout = 5000;
      } else timeout = Math.floor(Math.random() * (max - min + 1) + min);

      setTimeout(function() {
        that.deployStep += 1;
        if(that.deployStep <= 6) {
          that.scheduleDeployScreenUpdate();
        }
      }, timeout);
    },
    findServers(game, search_term) {
      return new Promise((resolve, reject) => {
        setTimeout(function() {
          fetch(process.env.VUE_APP_ROOT_API + `v1/servers/simple-query?game=${game}&search_term=${search_term}`, {credentials: 'include'})
          //fetch(`https://api.cftools.cloud/app/v1/servers/simple-query?game=${game}&search_term=${search_term}`, {credentials: 'include'})
            .then(response => {
              if(response.ok){
                return response.json();
              } else {
                throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              resolve(data.results)
            })
            .catch(error => {
              reject(error);
            });
        }, 500);
      });
    },
    doGetServers(search_term) {
      if(this.requestingServers) return;
      this.requestingServers = true;
      setTimeout(function() {
        this.requestingServers = false;
      }, 3000);
      this.findServers(this.game.id, search_term).then(response => {
        this.servers = response;
        this.requestingServers = false;
      });
    },
    getServers(search_term) {
      if(this.requestServersJob) clearTimeout(this.requestServersJob);
      let that = this;
      this.requestServersJob = setTimeout(function() {
        that.doGetServers(search_term);
      }, 500);
    },
    clearAll () {
      this.servers = []
    },
    toggleDirectConnect() {
      this.directConnectForm = !this.directConnectForm;
      this.resetForms();
    },
    onServerSelect(action) {
      this.quick_connect.rcon_port = action.host.game_port + 3;
    },
    toggleOverlay: function() {
      document.body.classList.toggle("fullpage-overlay-enabled");
    },
    handleDeploy(data) {
      this.deployConfirmed = true;
      this.deployedContinent = data.region;
      this.deployedServerId = data.server_id;

      if(this.deployStep > 6) {
        this.toggleOverlay();
        this.$router.push(`/server/${this.deployedServerId}`);
      }
    }
  },
  created() {
    this.getData();
    this.$socket.client.on('omega:deploy', this.handleDeploy);
  },
  destroyed() {
    this.$socket.client.off('omega:deploy', this.handleDeploy);
  },
  mounted() {
    setTimeout(() => {
      try {
        document.getElementById('serverSelect').setAttribute('autocomplete', 'off');
      } catch(e) {
        //
      }
    }, 1000);
  },
  data() {
    return {
      deployStep: 0,
      deployConfirmed: false,
      deployedContinent: null,
      deployedServerId: null,
      ready: false,
      eligible: false,
      limits_reached: false,
      limits: {},
      pending_server_id: null,
      // Form
      game: null,
      supported_games: [
        { title: 'DayZ', id: '1', desc: 'Bohemia Interactive', img: '/dayz_32x32.png' },
        { title: 'ArmA 2', id: '2', desc: 'Bohemia Interactive', img: '/arma2_32x32.png' },
        { title: 'ArmA 3', id: '3', desc: 'Bohemia Interactive', img: '/arma3_32x32.png' },
        //{ title: 'Rust', id: '4', desc: 'Facepunch Studios', img: '/rust_32x32.png' },
      ],
      inProgress: false,
      directConnectForm: false,
      requestServersJob: null,
      requestingServers: false,
      server: null,
      servers: [],
      quick_connect: {
        rcon_port: null,
        rcon_password: null
      },
      direct_connect: {
        host: null,
        game_port: null,
        rcon_port: null,
        rcon_password: null
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="fullpage-overlay">
      <div class="fullpage-overlay-content">
        <h3 class="text-white">
          <i class="fad fa-spinner-third fa-spin mr-2"></i>
          {{ $t('server_add.server.deploy.title')}}
        </h3>
        <div class="row justify-content-center">
          <div class="ml-5 pl-5 col-xl-2 text-left">
            <transition-group name="text-slide-fade">
              <h4 class="text-align-left" v-if="deployStep > 0" :key="0">
                <i class="fad fa-check-circle text-success mr-2"></i>
                <span class="text-muted">{{ $t('server_add.server.deploy.step.0')}}</span>
              </h4>
              <h4 v-if="deployStep > 1" :key="1">
                <i class="fad fa-check-circle text-success mr-2"></i>
                <span class="text-muted">{{ $t('server_add.server.deploy.step.1')}}</span>
              </h4>
              <h4 v-if="deployStep > 2" :key="2">
                <i class="fad fa-check-circle text-success mr-2"></i>
                <span class="text-muted">{{ $t('server_add.server.deploy.step.2')}}
                  <template v-if="deployConfirmed && deployedContinent">
                    <flag iso="eu" v-if="deployedContinent === 'EU'"/>
                    <flag iso="ca" v-else-if="deployedContinent === 'NA'"/>
                    <flag iso="sg" v-else-if="deployedContinent === 'AS'"/>
                    <flag iso="au" v-else-if="deployedContinent === 'OC'"/>
                  </template>
                </span>
              </h4>
              <h4 v-if="deployStep > 3" :key="3">
                <i class="fad fa-check-circle text-success mr-2"></i>
                <span class="text-muted">{{ $t('server_add.server.deploy.step.3')}}</span>
              </h4>
              <h4 v-if="deployStep > 4" :key="4">
                <i class="fad fa-check-circle text-success mr-2"></i>
                <span class="text-muted">{{ $t('server_add.server.deploy.step.4')}}</span>
              </h4>
              <h4 v-if="deployStep > 5" :key="5">
                <i class="fad fa-check-circle text-success mr-2"></i>
                <span class="text-muted">{{ $t('server_add.server.deploy.step.5')}}</span>
              </h4>
            </transition-group>
          </div>
        </div>
        <div class="row justify-content-center mt-2" v-if="deployStep > 6">
          <div class="col-xl-1 col-4">
            <router-link to="/dashboard">
            <button class="btn btn-primary btn-block btn-sm align-self-center" @click="toggleOverlay">
              {{ $t('terms.continue')}}
            </button>
            </router-link>
          </div>
          <div class="col-xl-12">
            <small>
              {{ $t('server_add.server.deploy.longer')}}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-if="!ready">
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <vcl-code></vcl-code>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!eligible">
        <div class="row justify-content-center mt-lg-5">
          <div class="col-xl-5 col-sm-8">
            <div class="card">
              <div class="card-body">
                <div class="text-center">
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2 class="mt-4">
                        <i class="fas fa-frown text-warning"></i>
                      </h2>
                      <h4 class="mt-4 font-weight-semibold">
                        {{ $t('server_add.not_eligable.no_subscription.title')}}
                      </h4>
                      <p class="text-muted mt-3">
                        {{ $t('server_add.not_eligable.no_subscription.description')}}
                      </p>
                      <b class="text-muted mt-3">
                        {{ $t('server_add.not_eligable.no_subscription.call2action')}}
                      </b>
                      <div class="mt-4">
                        <router-link to="/subscribe">
                          <button class="btn btn-success btn-lg">
                            {{ $t('server_add.not_eligable.no_subscription.button')}}
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="limits_reached">
        <div class="row justify-content-center mt-lg-5">
          <div class="col-xl-5 col-sm-8">
            <div class="card">
              <div class="card-body">
                <div class="text-center">
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <h2 class="mt-4">
                        <i class="fas fa-frown text-warning"></i>
                      </h2>
                      <h4 class="mt-4 font-weight-semibold">
                        {{ $t('server_add.not_eligable.limits_reached.title')}}
                      </h4>
                      <p class="text-muted mt-3">
                        {{ $t('server_add.not_eligable.limits_reached.description')}}
                      </p>
                      <b class="text-muted mt-3">
                        {{ $t('server_add.not_eligable.limits_reached.call2action')}}
                      </b>
                      <div class="mt-4">
                        <router-link to="/subscribe">
                          <button class="btn btn-success btn-lg">
                            {{ $t('server_add.not_eligable.limits_reached.button')}}
                          </button>
                        </router-link>
                      </div>
                      <div class="progress mt-4" style="height: 40px;">
                        <div role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" class="progress-bar bg-danger" style="width: 100%; font-size: 24px;">
                          {{ limits.servers }} / {{ limits.servers }} {{ $t('terms.serversl')}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row justify-content-center">
          <div class="col-xl-7">
            <div class="card">
              <div class="card-header">
                Add a new server to CFTools Cloud
              </div>
              <div class="card-body" style="min-height: 20rem">
                <div class="row justify-content-center">
                  <div class="col-xl-8 col-md-12 col-sm-12">
                    <template v-if="game !== null">
                      <template v-if="game.id === '1'">
                        <div class="row">
                          <div class="col">
                            <div class="alert alert-warning">
                              <h5 class="alert-heading">
                                DayZ RCon configuration
                              </h5>
                              <hr>
                              <p>
                                RCon for DayZ requires additional configuration. You can get more information at <a href="https://docs.cftools.cloud/en/connection/battleye" target="_blank">docs.cftools.cloud/en/connection/battleye</a>.
                              </p>
                              <p>
                                Make sure that <b class="text-code">RConPort</b> is configured and forwarded in your firewall. <b class="text-code">RestrictRCon</b> must be <b class="text-code">0</b> in order for CFTools Cloud to function correctly.
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                    <label class="typo__label">
                      {{ $t('server_add.step.1') }}
                    </label>
                    <multiselect
                        v-model="game"
                        label="title"
                        track-by="title"
                        :options="supported_games"
                        :show-labels="false"
                        :allow-empty="false"
                    >
                      <template
                          slot="singleLabel"
                          slot-scope="props"
                      >
                        <div>
                        <img
                            class="option__image"
                            :src="props.option.img"
                        >
                          <span class="option__desc d-inline ml-2">
                            <span class="option__title align-middle h4">
                              {{ props.option.title }}
                            </span>
                          </span>
                        </div>
                      </template>
                      <template
                          slot="option"
                          slot-scope="props"
                      >
                        <div>
                          <img class="option__image d-inline-block" :src="props.option.img">
                          <div class="option__desc d-inline ml-2">
                            <span class="option__title align-middle h4">
                              {{ props.option.title }}
                            </span>
                            <span class="option__small align-middle ml-2 h6">
                              {{ props.option.desc }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div v-if="game">
                  <div v-if="!directConnectForm">
                    <div class="row justify-content-center mt-3">
                      <div class="col-lg-8">
                        <label class="typo__label" for="serverSelect">
                          {{ $t('server_add.step.2') }}
                          <a href="#" v-on:click="toggleDirectConnect">
                            {{ $t('server_add.server.missing') }}
                          </a>
                        </label>
                        <form autocomplete="off" class="server-select">
                        <multiselect
                            v-model="server"
                            id="serverSelect"
                            label="name"
                            track-by="name"
                            open-direction="bottom"
                            :placeholder="$t('server_add.server.search')"
                            :options="servers"
                            :multiple="false"
                            :searchable="true"
                            :loading="requestingServers"
                            :internal-search="false"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="10"
                            :limit="1"
                            :max-height="150"
                            :show-no-results="false"
                            :hide-selected="true"
                            @search-change="getServers"
                            @select="onServerSelect"
                        >
                          <template
                              slot="tag"
                              slot-scope="{ option, remove }"
                          >
                            <span class="custom__tag">
                              <span>{{ option.name }}</span>
                              <span class="custom__remove" @click="remove(option)">❌</span>
                            </span>
                          </template>
                          <template slot="clear" slot-scope="props">
                            <div class="multiselect__clear" v-if="server" @mousedown.prevent.stop="clearAll(props.search)"></div>
                          </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                          <template slot="noOptions">
                            {{ $t('server_add.server.select_prompt') }}
                          </template>
                        </multiselect>
                        </form>
                      </div>
                    </div>
                    <div v-if="server">
                      <div class="row justify-content-center mt-3">
                        <div class="col-lg-8">
                          <label>
                            {{ $t('server_add.step.3') }}
                          </label>
                          <form action="#" @submit.prevent="formSubmit">
                            <div class="row">
                              <div class="col-lg-7 col-md-12 col-sm-12">
                                <div class="form-group">
                                  <label>
                                    {{ $t('server_add.server.direct_connect.rcon_password.label') }}
                                  </label>
                                  <div>
                                    <input
                                        v-model="quick_connect.rcon_password"
                                        type="text"
                                        class="form-control"
                                        name="between"
                                        autocomplete="off"
                                        :class="{ 'is-invalid': $v.quick_connect.rcon_password.$error }"
                                        :placeholder="$t('server_add.server.direct_connect.rcon_password.label')"
                                    />
                                    <div v-if="$v.quick_connect.rcon_password.$error" class="invalid-feedback">
                                    <span v-if="!$v.quick_connect.rcon_password.required">
                                      {{ $t('server_add.server.direct_connect.rcon_password.error.required') }}
                                    </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-5 col-md-12 col-sm-12">
                                <div class="form-group">
                                  <label>
                                    {{ $t('server_add.server.direct_connect.rcon_port.label') }}
                                  </label>
                                  <div>
                                    <input
                                        v-model="quick_connect.rcon_port"
                                        type="number"
                                        class="form-control"
                                        name="between"
                                        autocomplete="off"
                                        :class="{ 'is-invalid': $v.quick_connect.rcon_port.$error }"
                                        :placeholder="$t('server_add.server.direct_connect.rcon_port.label')"
                                    />
                                    <div v-if="$v.quick_connect.rcon_port.$error" class="invalid-feedback">
                                    <span v-if="!$v.quick_connect.rcon_port.required">
                                      {{ $t('server_add.server.direct_connect.rcon_port.error.required') }}
                                    </span>
                                      <span v-if="!$v.quick_connect.rcon_port.maxValue">
                                      {{ $t('server_add.server.direct_connect.rcon_port.error.value') }}
                                    </span>
                                      <span v-if="!$v.quick_connect.rcon_port.minValue">
                                      {{ $t('server_add.server.direct_connect.rcon_port.error.value') }}
                                    </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row pb-2">
                              <div class="col-xl">
                                <button class="btn btn-primary btn-block" :disabled="inProgress">
                                  <i class="fad fa-plus text-dark" v-if="!inProgress"></i>
                                  <small class="spinner-border text-dark mr-1" v-if="inProgress" style="width: 16px; height: 16px;"></small>
                                  {{ $t('server_add.server.direct_connect.button') }}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="directConnectForm">
                    <form action="#" @submit.prevent="formSubmit">
                      <div class="row justify-content-center mt-3">
                        <div class="col-lg-8">
                          <label>
                            {{ $t('server_add.step.2b') }}
                            <a href="#" v-on:click="toggleDirectConnect">
                              {{ $t('server_add.server.select') }}
                            </a>
                          </label>
                          <div class="row">
                            <div class="col-lg-7 col-md-12 col-sm-12">
                              <div class="form-group">
                                <label>
                                  {{ $t('server_add.server.direct_connect.host.label') }}
                                </label>
                                <div>
                                  <input
                                      v-model="direct_connect.host"
                                      type="text"
                                      class="form-control"
                                      name="between"
                                      autocomplete="off"
                                      :class="{ 'is-invalid': $v.direct_connect.host.$error }"
                                      :placeholder="$t('server_add.server.direct_connect.host.label')"
                                  />
                                  <div v-if="$v.direct_connect.host.$error" class="invalid-feedback">
                                    <span v-if="!$v.direct_connect.host.required">
                                      {{ $t('server_add.server.direct_connect.host.error.required') }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-5 col-md-12 col-sm-12">
                              <div class="form-group">
                                <label>
                                  {{ $t('server_add.server.direct_connect.game_port.label') }}

                                  <small class="text-muted">
                                    {{ $t('server_add.server.direct_connect.game_port.description') }}
                                  </small>
                                </label>
                                <div>
                                  <input
                                      v-model="direct_connect.game_port"
                                      type="number"
                                      class="form-control"
                                      name="between"
                                      autocomplete="off"
                                      :class="{ 'is-invalid': $v.direct_connect.game_port.$error }"
                                      :placeholder="$t('server_add.server.direct_connect.game_port.label')"
                                  />
                                  <div v-if="$v.direct_connect.game_port.$error" class="invalid-feedback">
                                    <span v-if="!$v.direct_connect.game_port.required">
                                      {{ $t('server_add.server.direct_connect.game_port.error.required') }}
                                    </span>
                                    <span v-if="!$v.direct_connect.game_port.maxValue">
                                      {{ $t('server_add.server.direct_connect.game_port.error.value') }}
                                    </span>
                                    <span v-if="!$v.direct_connect.game_port.minValue">
                                      {{ $t('server_add.server.direct_connect.game_port.error.value') }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-7 col-md-12 col-sm-12">
                              <div class="form-group">
                                <label>
                                  {{ $t('server_add.server.direct_connect.rcon_password.label') }}
                                </label>
                                <div>
                                  <input
                                      v-model="direct_connect.rcon_password"
                                      type="text"
                                      class="form-control"
                                      name="between"
                                      autocomplete="off"
                                      :class="{ 'is-invalid': $v.direct_connect.rcon_password.$error }"
                                      :placeholder="$t('server_add.server.direct_connect.rcon_password.label')"
                                  />
                                  <div v-if="$v.direct_connect.rcon_password.$error" class="invalid-feedback">
                                    <span v-if="!$v.direct_connect.rcon_password.required">
                                      {{ $t('server_add.server.direct_connect.rcon_password.error.required') }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-5 col-md-12 col-sm-12">
                              <div class="form-group">
                                <label>
                                  {{ $t('server_add.server.direct_connect.rcon_port.label') }}
                                </label>
                                <div>
                                  <input
                                      v-model="direct_connect.rcon_port"
                                      type="number"
                                      class="form-control"
                                      name="between"
                                      autocomplete="off"
                                      :class="{ 'is-invalid': $v.direct_connect.rcon_port.$error }"
                                      :placeholder="$t('server_add.server.direct_connect.rcon_port.label')"
                                  />
                                  <div v-if="$v.direct_connect.rcon_port.$error" class="invalid-feedback">
                                    <span v-if="!$v.direct_connect.rcon_port.required">
                                      {{ $t('server_add.server.direct_connect.rcon_port.error.required') }}
                                    </span>
                                    <span v-if="!$v.direct_connect.rcon_port.maxValue">
                                      {{ $t('server_add.server.direct_connect.rcon_port.error.value') }}
                                    </span>
                                    <span v-if="!$v.direct_connect.rcon_port.minValue">
                                      {{ $t('server_add.server.direct_connect.rcon_port.error.value') }}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row pb-2">
                            <div class="col-xl">
                              <button class="btn btn-primary btn-block" :disabled="inProgress">
                                <i class="fad fa-plus text-dark" v-if="!inProgress"></i>
                                <small class="spinner-border text-dark mr-1" v-if="inProgress" style="width: 16px; height: 16px;"></small>
                                {{ $t('server_add.server.direct_connect.button') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
